<template>
  <v-row dense class="my-0 mt-2 py-0">
    <v-col cols="12" md="6" lg="5">
      <v-card class="px-1 py-0">
        <v-container fluid class="m-0 pb-1">
          <v-row class="m-0 p-0">
            <v-col>
              <p class="m-0 p-0 mt-n3 ms-2 f-lato fs-11pt fw-700">Geral</p>
              <p class="m-0 p-0 ms-2 mt-2 f-roboto fs-20pt fw-500 fh-1 fsp-n2" v-cHex="'#0a678f'">
                {{qtde.encomendas}}
                <span class="ms-1 fs-11pt f-lato fw-300 fsp-0">itens encomendados</span>
              </p>
              <p class="m-0 p-0 ms-2 mt-1 f-roboto fs-16pt fw-800 fh-1 fsp-n2" v-cHex="'#125036'">
                {{qtde.liberado}}
                <span class="ms-1 fs-11pt f-lato fw-400 fsp-0">itens liberados</span>
              </p>
            </v-col>
            <v-col cols="5">
              <semidonut class="" :height="190" :offsetY="-30" color="#125036" :values="[porcLiberados]"/>
              <p class="m-0 p-0 mt-n3 text-center f-lato fs-11pt fw-700" v-cHex="hexShades('#125036',0.2)">
                Liberados
              </p>
            </v-col>
            <v-col cols="12" class="my-1 mt-n2 py-0">
              <p class="m-0 p-0 ms-2 mt-0 f-roboto  fw-800 fh-1">
                <span class="ms-2 me-1 fs-12pt fsp-n1" v-cHex="'#22a76f'">{{qtde.revisao}}</span>
                <span class="ms-0 me-1 fs-8pt f-lato fw-700 fsp-0" v-cHex="'#22a76f'">Revisão</span>
                <span class="ms-2 me-1 fsp-n1" v-cHex="'#9e2a00'">{{qtde.correcao}}</span>
                <span class="ms-0 fs-8pt f-lato fw-700 fsp-0" v-cHex="'#9e2a00'">Correção</span>
                <span class="ms-2 me-1 fsp-n1" v-cHex="'#0373ba'">{{qtde.aguardando}}</span>
                <span class="ms-0 fs-8pt f-lato fw-700 fsp-0" v-cHex="'#0373ba'">Aguardando</span>
                <span class="ms-2 me-1 fsp-n1" v-cHex="'#999999'">{{qtde.naoVisualizados}}</span>
                <span class="ms-0 fs-8pt f-lato fw-400 fsp-0" v-cHex="'#999999'">Não visualizados</span>
              </p>
            </v-col>
          </v-row>
        </v-container>
        <v-overlay absolute :value="loading">
          <div class="text-center">
            <v-progress-circular color="white" indeterminate size="64"></v-progress-circular>
          </div>
          <div class="text-center mb-4" style="margin-top: 20px;">
            <img width="180px" class="mt-n1" src="https://firebasestorage.googleapis.com/v0/b/appintegra-d8424.appspot.com/o/appintegra%2Flogos%2Flogo-800.png?alt=media&token=ff063aea-169b-4c6b-86ec-bc872bffeab1" alt="">
          </div>
        </v-overlay>
      </v-card>
    </v-col>
    <v-col cols="12" md="6" lg="7">
      <v-card class="px-1 py-0">
        <v-container fluid class="m-0 pb-1">
          <v-row class="m-0 p-0">
            <v-col>
              <p class="m-0 p-0 mt-n3 ms-2 f-lato fs-11pt fw-700">Visualização / Revisão / Correção / Liberados</p>
              <hr class="my-1 mb-3">
              <progressmulti :height="18" :values="[emRevisao,emCorrecao,porcLiberados,porcAguardando,porcNaoVisualizados]" :colors="['#22a76f','#9e2a00','#125036','#0373ba','#bbbbbb']" :labels="['Revisão', 'Correção', 'Liberados', 'Aguardando', 'Não visualizados']" />
            </v-col>
          </v-row>
        </v-container>
        <v-overlay absolute :value="loading">
          <div class="text-center">
            <v-progress-circular color="white" indeterminate size="64"></v-progress-circular>
          </div>
          <div class="text-center mb-4" style="margin-top: 20px;">
            <img width="180px" class="mt-n1" src="https://firebasestorage.googleapis.com/v0/b/appintegra-d8424.appspot.com/o/appintegra%2Flogos%2Flogo-800.png?alt=media&token=ff063aea-169b-4c6b-86ec-bc872bffeab1" alt="">
          </div>
        </v-overlay>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js';
import semidonut from "./semi-donut.vue";
import progressmulti from "./progress-multi.vue";

export default {
  name: "geralcard",
  components: { semidonut, progressmulti },
  props: [],
  computed: {
    emRevisao() {
      return parseFloat(this.qtde.revisao / (this.qtde.encomendas-this.qtde.liberado) * 100).toFixed(2);
    },
    emCorrecao() {
      return parseFloat(this.qtde.correcao / (this.qtde.encomendas-this.qtde.liberado) * 100).toFixed(1);
    },
    porcLiberados() {
      return parseFloat(this.qtde.liberado / (this.qtde.encomendas) * 100).toFixed(1);
    },
    porcNaoVisualizados() {
      return parseFloat(this.qtde.naoVisualizados / (this.qtde.encomendas) * 100).toFixed(1);
    },
    porcAguardando() {
      //var qtde = this.qtde.revisao + this.qtde.correcao + this.qtde.liberado + this.qtde.naoVisualizados;
      return parseFloat(this.qtde.aguardando / (this.qtde.encomendas) * 100).toFixed(1);
    }
  },
  data(){
    return {
      loading: false,
      refresh: 0,
      encomendas: {},
      entregas: {},
      profsCientes: {},
      qtde: { encomendas: 0, entregas: 0, naoVisualizados: 0, aguardando: 0, revisao: 0, correcao: 0, liberado: 0 },
    }
  },
  watch: {},
  mounted: function() {
    var self = this;
    self.loading = true;

    rdb.ref('/curriculo/avaliacao/2021S2/profsCientes').on('value',function(snapshot) {
      self.profsCientes = snapshot.val();
      console.log("self.profsCientes ["+Object.keys(self.profsCientes).length+"]",self.profsCientes);
      self.build();
    });

    rdb.ref('/curriculo/avaliacao/2021S2/encomendasA2').on('value',function(snapshot) {
      self.encomendas = snapshot.val();
      if(self.encomendas != null) {
        console.log("self.encomendas ["+Object.keys(self.encomendas).length+"]",self.encomendas);
      } else {
        self.encomendas = {};
      }
      self.qtde.encomendas = Object.keys(self.encomendas).length;
      /*rdb.ref('/curriculo/avaliacao/2021S2/entregasA2Limpo').on('value',function(snapshot) {
        self.entregas = snapshot.val();
        if(self.entregas != null) {
          console.log("self.entregas ["+Object.keys(self.entregas).length+"]",self.entregas);
        } else {
          self.entregas = {};
        }
        self.qtde.entregas = Object.keys(self.entregas).length;
        self.loading = false;
        self.build();
      });*/
    });
  },
  methods:{

    build() {
      var self = this;
      self.qtde.revisao = 0;
      self.qtde.correcao = 0;
      self.qtde.liberado = 0;

      /*for(var key in self.entregas) {
        //console.log(self.entregas[key].revisaoStatus);
        if(self.entregas[key].revisaoStatus == "Revisão") {
          self.qtde.revisao++;
        }
        if(self.entregas[key].revisaoStatus == "Correção") {
          self.qtde.correcao++;
        }
        if(self.entregas[key].revisaoStatus == "Liberado") {
          self.qtde.liberado++;
        }
      }*/

      rdb.ref('/curriculo/avaliacao/2021S2/contadores').on('value',function(snapshot) {
        var contadores = snapshot.val();
        self.qtde.revisao = contadores.revisao;
        self.qtde.correcao = contadores.correcao;
        self.qtde.liberado = contadores.liberados;

        var cientes = 0;
        for(var key in self.encomendas) {
          if(self.profsCientes[self.encomendas[key].profID] != undefined) {
            cientes++;
          }
        }
        self.qtde.naoVisualizados = self.qtde.encomendas - cientes;
        self.qtde.aguardando = cientes - self.qtde.liberado - self.qtde.revisao - self.qtde.correcao;
        console.log("qtde",self.qtde);
        self.loading = false;
      });

    }
  }
}
</script>

<style scoped>
</style>
