<template>
  <v-row class="mt-0 p-0">

    <geralcard />

    <v-col cols="12" sm="6" md="4" lg="3" v-for="(area, key, index) in areas" :key="index">
      <areacard :area="area" @areaClick="areaClick"/>
    </v-col>
    <areadetalhes ref="areadetalhesref" />
  </v-row>
</template>

<script>
import { rdb, snapshotValToArray, generatePushID } from '@/firebasedb.js';
import avataruser from "./avatar-user.vue";
import { exportarPlanilha } from "@/components/export-planilha.js";
import tagarea from "@/views/cursos/tagArea.vue";
import areacard from "./area-card.vue";
import geralcard from "./geral-card.vue";

export default {
  name: "acompanhamento",
  components: {
    avataruser, tagarea, areacard, geralcard,
    'areadetalhes': () => import('./area-detalhes.vue'),
  },
  props: [],
  computed: {},
  data(){
    return {
      loading: false,
      refresh: 0,
      areas: {},
    }
  },
  watch: {
    filtro() {
      this.build();
    }
  },
  mounted: function() {
    var self = this;

    self.loading = true;
    rdb.ref('/curriculo/areas').on('value',function(snapshot) {
      self.areas = snapshot.val();
      console.log("self.areas ["+Object.keys(self.areas).length+"]",self.areas);
    });

    //rdb.ref('/curriculo/avaliacao/2021S2/entregasA2').on('value',function(snapshot) {
    //  self.entregasA2 = snapshot.val();
    //  console.log("self.entregasA2 ["+Object.keys(self.entregasA2).length+"]",self.entregasA2);
    //});
  },
  methods:{

    build() {
      var self = this;
    },

    areaClick(area) {
      var self = this;
      this.$refs.areadetalhesref.show(area)
    }
  }
}
</script>

<style scoped>
</style>
